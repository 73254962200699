<template>
  <div class="">
    <TopBarTitleComponent title="Términos y Condiciones" />

    <b-container fluid class="pb-4 px-lg-4">
      <TermsPayButtonRecurrent />
    </b-container>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import TermsPayButtonRecurrent from "@/components/General/TermsPayButtonRecurrent";

export default {
  name: "PlanTermsComponent",
  data: () => ({}),
  computed: {},
  methods: {},
  components: {
    TermsPayButtonRecurrent,
    TopBarTitleComponent
  }
};
</script>

<style lang="scss" scoped></style>

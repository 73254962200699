<template>
  <div style="background-color: white; padding: 25px; border-radius: 50px;">
    <h6 class="text-center text-color-primary font-bold mb-4">
      Términos y Condiciones para el Cobro de Suscripción Recurrente de la
      Plataforma Mydessk
    </h6>
    <p>
      <strong>1. Definiciones: </strong><br />
      a. "Plataforma Mydessk": Hace referencia a la plataforma en línea operada
      por Mydessk Cia. Ltda., que ofrece servicios y contenido relacionados con
      tecnología, facturación electrónica. E-commerce, entre otros.<br />
      b. "Suscriptor": Persona física o jurídica que ha completado el proceso de
      registro en la Plataforma Mydessk y ha aceptado los presentes términos y
      condiciones. <br />c. "Tarjeta Habiente": Persona física titular de la
      tarjeta de crédito o débito utilizada para efectuar el pago de la
      suscripción recurrente.
    </p>
    <p>
      <strong>2. Suscripción Recurrente y Cobro: </strong><br />
      a. Al completar el proceso de registro en la Plataforma Mydessk, el
      Suscriptor acepta y autoriza el cobro mensual recurrente del valor de
      suscripción correspondiente, utilizando la tarjeta de crédito o débito
      proporcionada durante el proceso de registro. <br />b. El valor de la
      suscripción recurrente será especificado en la página de registro y podrá
      estar sujeto a cambios previa notificación al Suscriptor. <br />c. El
      cobro mensual se realizará automáticamente en la fecha de aniversario del
      registro inicial del Suscriptor. El Suscriptor entiende y acepta que es su
      responsabilidad asegurarse de que haya fondos suficientes en la tarjeta
      asociada para cubrir el pago. <br />d. En caso de que el pago recurrente
      sea rechazado por falta de fondos u otros motivos, el acceso a los
      servicios de la Plataforma Mydessk podrá ser suspendido hasta que se
      regularice el pago.
    </p>

    <p>
      <strong>3. Modificaciones y Cancelación: </strong><br />
      a. El Suscriptor puede cancelar su suscripción recurrente en cualquier
      momento, accediendo a su cuenta en la Plataforma Mydessk y siguiendo los
      pasos indicados para cancelar la suscripción. <br />b. Los cambios en el
      valor de la suscripción recurrente serán notificados al Suscriptor con al
      menos 5 días de antelación antes de que los mismos entren en vigencia.
    </p>

    <p>
      <strong>4. Responsabilidad y Garantías: </strong>
      a. La Plataforma Mydessk se compromete a proporcionar los servicios según
      lo descrito en su sitio web y en línea con los más altos estándares de
      calidad.
      <br />b. El Suscriptor reconoce que el acceso y uso de los servicios de la
      Plataforma Mydessk están sujetos a posibles interrupciones técnicas, y la
      empresa no será responsable por daños o perjuicios causados por dichas
      interrupciones.
    </p>
    <p>
      <strong>5. Privacidad y Datos Personales: </strong><br />
      a. La Plataforma Mydessk recopila, procesa y almacena los datos personales
      de los Suscriptores de acuerdo con su Política de Privacidad. Al aceptar
      estos términos y condiciones, el Suscriptor también acepta los términos de
      la Política de Privacidad de la plataforma.
    </p>
    <p>
      <strong>6. Ley Aplicable y Jurisdicción: </strong><br />
      a. Estos términos y condiciones se rigen por las leyes de Ecuador y
      cualquier disputa que surja en relación con los mismos será sometida a la
      jurisdicción exclusiva de los tribunales competentes en Guayaquil,
      Ecuador.<br />
      Al aceptar estos términos y condiciones, el Suscriptor declara haber
      leído, comprendido y aceptado todas las disposiciones aquí contenidas. La
      aceptación de estos términos y condiciones se considerará como un contrato
      válido entre el Suscriptor y Mydessk Cia. Ltda.
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsPayButtonRecurrent"
};
</script>

<style></style>
